@import '../../../styles/customMediaQueries.css';

.root {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    padding-left: 7.5vw;
    padding-right: 7.5vw;
  }

  @media (--viewportLarge) {
    padding: 96px 7.5vw;
  }
}

.title {
  text-align: center;
  max-width: 70vw;

  @media (--viewportMedium) {
    margin-bottom: 25px;
  }
}

.contextLine {
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 30px;

  @media (--viewportMedium) {
    margin-top: 50px;
  }
}

.sectionHowItWorks {
  display: flex;
  flex-direction: column;

  gap: 10px;

  @media (--viewportLarge) {
    gap: 44px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (--viewportLarge) {
    gap: 68px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid var(--colorBorderGrey);
  border-top: 4px solid var(--colorLightRed);
  border-radius: 4px;
  padding: 12px 24px;

  & > h4 {
    margin-bottom: 0px;
  }

  & > p {
    margin-top: 0px;
  }
}

.heading,
.content {
  text-align: left;
}

.wizardTitle {
  composes: h3 from global;
  margin: 0;
  text-align: center;
}

.wizardSubTitle {
  text-align: center;
  font-weight: 300;
}

.header {
  margin-bottom: 32px;
}

.footNote {
  composes: marketplaceModalHelperText from global;
  white-space: pre-line;
  text-align: center;
  margin-top: 48px;
}

.loginButton {
  margin-top: 32px;
}

.customField {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.customLocationAutocompleteInputIcon {
  display: none;
}

.customPredictionsRoot {
  position: absolute;
  width: 100%;
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
}

.firstName {
  width: calc(34% - 9px);
}

.lastName {
  width: calc(66% - 9px);
}

.checkmark {
  margin: 48px auto;
}

.animatedHeader {
  padding-bottom: 24px;
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out 0.8s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
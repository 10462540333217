@import '../../../styles/customMediaQueries.css';

.root {
  background-color: var(--colorGrey100);
  padding: 42px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  gap: 50px;

  @media (--viewportMedium) {
    padding: 48px 24px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  & > img {
    height: 30px;

    @media (--viewportLarge) {
      height: 40px;
    }
  }
}

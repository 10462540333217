@import '../../../styles/customMediaQueries.css';

.root {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    padding: 0px 48px 32px;
  }

  @media (--viewportLarge) {
    padding: 0px 7.5vw 64px;
  }
}

.typesOfJob {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;

    & > a {
      flex-basis: 30%;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  color: var(--colorBlack);

  & img {
    width: 100%;
    height: 260px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  @media (--viewportMedium) {
    & img {
      height: 200px;
    }
  }

  @media (--viewportLarge) {
    & img {
      height: 300px;
    }
  }

  @media (--viewportXLarge) {
    & img {
      height: 400px;
    }
  }

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColorLight);
  }
}

.itemTitle {
  text-align: center;
  font-weight: var(--fontWeightBold);
}

@import '../../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 60vh;
}

.heroContent {
  display: flex;
  flex-direction: column;
  margin: auto 24px 56px;
  max-width: 704px;
  background: rgba(0, 0, 0, 0.3);
  padding: 29px 24px 19px;
  border-radius: 12px;

  @media (--viewportMedium) {
    margin-left: 7.5vw;
    margin-right: 7.5vw;
    margin-bottom: 7.5vh;
  }

  @media (--viewportLarge) {
    padding-left: 41px;
    padding-right: 38px;
  }
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;
  composes: animation;
  color: var(--colorWhite);
  margin-top: 0;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}
.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  composes: h4 from global;

  color: var(--colorWhite);
  margin: 0;
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}
.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.searchForm {
  display: flex;
  flex-direction: row;
  composes: animation;
  gap: 2vw;
  /* align-items: center; */

  @media (--viewportMedium) {
    gap: 1vw;
  }

  @media (--viewportLarge) {
    width: 70vw;
  }

  @media (--viewportLargeWithPaddings) {
    width: 50vw;
  }
}

.searchField {
  flex-basis: 100%;
}

.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  display: block;
  width: 200px;
  margin-top: 32px;
  @media (--viewportMedium) {
    margin-top: 47px;
  }
}

.heroButtonMobile {
  display: block;
  padding: 0px 20px;
  @media (--viewportMedium) {
    display: none;
  }
}

.searchIcon {
  stroke: var(--colorWhite);
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}
